<template>
  <div class="date-time-container">
    <div class="row">
      <p>Yıl Seç:</p>
      <p
        v-for="(year, i) in yearList"
        :key="i"
        @click="selectYearHandler(year)"
        :class="{ active: selectedYear === year }"
      >
        {{ year }}
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import moment from "moment";

export default {
  name: "DateTimePicker",
  props: ["componentName"],

  created() {
    EventBus.$on(`${this.componentName}-reset`, this.reset);
  },

  beforeDestroy() {
    EventBus.$off(`${this.componentName}-reset`, this.reset);
  },

  mounted() {
    this.yearList = this.generateYearList();
    this.selectedYear = moment().year();
    this.emitEvent();
  },

  data() {
    return {
      yearList: [],
      selectedYear: null,
    };
  },

  methods: {
    reset() {
      this.selectedYear = moment().year();
    },

    emitEvent() {
      EventBus.$emit(this.componentName, this.selectedYear);
    },

    generateYearList() {
      let firstYear = 2023;
      let yearList = [];
      let currentYear = moment().year();

      if (currentYear - firstYear >= 10) {
        firstYear = currentYear - 10;
      }

      for (let i = currentYear; i >= firstYear; i--) {
        yearList.push(i);
      }
      return yearList;
    },

    selectYearHandler(val) {
      this.selectedYear = val;
      this.emitEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-time-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  width: 100%;
  position: relative;
  left: 0;

  @media screen and (max-width: 1200px) {
    top: 0;
    margin: 5px 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #ccc;
    border-radius: 5px;

    p {
      cursor: pointer;
      padding: 5px 10px;
      border-right: 0.5px solid #ccc;
      font-size: 13px;
      margin: 0;

      &:hover {
        background-color: #ff5d59;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: default;
        &:hover {
          background-color: white;
        }
      }

      &:last-child {
        border-right: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .active {
    background-color: #e62f0e;
    color: #fff;
  }
}
</style>
